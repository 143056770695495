@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
font-family: "ProximaNova";
src: local("fonts"),
 url("./fonts/ProximaNova-Regular.otf") format("truetype");
font-weight: normal;
}

@font-face {
  font-family: "Futura Bold";
  src: local("fonts"),
   url("./fonts/Futura-Bold.otf") format("truetype");
  font-weight: bold;
  }

body {
  font-family: "ProximaNova";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #302E2E;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align:center;
}

h1, h2, h3, h4, h5, h6, .nav-link {
  font-family: "Futura Bold" !important;
}

.wrapper {
  flex: 1;
}

.case-study-repeater div div .case-study-image:not(:first-child) {
  height: 60px;
}

/* sidebar Nav */
.navBar {
  position: relative;
}

.navBar button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  cursor: pointer;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: #F1EEE7;
  right: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  z-index: 10;
}

.menuNav.showMenu {
  width: 100vw;
  transition: width 0.35s;
}

/* Icon 3 */
#nav-icon {
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 34px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #302E2E;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 12px;
}

#nav-icon span:nth-child(4) {
  top: 24px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

.screen {
  background-color: rgb(250, 243, 237) !important;
}

.iphone {
  height: 573px;
  overflow: hidden;
}

.iphone img {
  animation: scrollImage 20s linear infinite;
  height: auto;
  width: 100%;
  display: block;
}

@keyframes scrollImage {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-80%);
  }
}

/* Sephora laptop slide preview */
.slideshow {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slides {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 49%;
  translate: -50%;
  width: 65%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slides img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 1s ease;
}

.slides img.active {
  opacity: 1;
}
